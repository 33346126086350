"use client";

import DRAFT_MODE_SECRET from "@/lib/preview/DRAFT_MODE_SECRET";
import { Portal } from "@headlessui/react";
import { usePathname } from "next/navigation";
import { useState } from "react";
import { useEffectOnce } from "react-use";

function DraftModeBanner({ enabled }: { enabled: boolean }) {
  const [visible, setVisible] = useState(true);

  const pathname = usePathname();

  useEffectOnce(() => {
    if (enabled) {
      setTimeout(() => {
        setVisible(false);
      }, 10 * 1000);
    }
  });

  if (!visible) {
    return null;
  }
  return (
    <Portal>
      <a
        className={`fixed shadow top-12 -right-12 p-2 transform rotate-45 ${
          enabled ? "bg-yellow-200 text-primary" : "bg-red-500 text-primary-50"
        } w-56 items-center justify-center flex flex-row`}
        style={{
          zIndex: 1000,
        }}
        href={`/admin/api/draft-mode/${
          enabled ? "disable" : "enable"
        }?postSlug=${pathname.replace(
          /^\//,
          ""
        )}&secret=${DRAFT_MODE_SECRET}&postType=page`}
      >
        <span className="text-sm font-semibold">
          {enabled ? "Draft mode enabled" : "Draft mode not enabled"}
        </span>
      </a>
    </Portal>
  );
}

export default DraftModeBanner;
